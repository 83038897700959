<template>
    <div class="card mt-4">
                      <div class="card-header">
                          <div class=" text-center">
                  <div><h3>VB REPORT</h3></div>
                  <!-- <div>
                      <date-range-picker
      v-model="dateRange"
      @update="dateChange"
      :locale-data="{ format: 'dd-mm-yyyy' }"
    ></date-range-picker>
              </div> -->
              <div class="row">
                      <div class="col-md-3">

                      <multiselect
                  :options="vendor_ids"
                  placeholder="Select Vendor"
                  v-model="vendor_id_value"
                  track-by="name"
                          label="name"
                >
                </multiselect>
					</div>
					<div class="col-md-1 text-center">
<b-button variant="primary" @click="apply_filter()">Apply</b-button>
					</div>
                      <div class="col-md-4 text-center">
                          <date-range-picker
      v-model="dateRange"
      @update="dateChange"
      :locale-data="{ format: 'dd-mm-yyyy' }"
    ></date-range-picker>
                      </div>
                      <div class="col-md-4 text-right">
    <button  type="button" class="btn btn-venuecolor"><i class="mdi mdi-export"></i>Export</button>
  
              </div>
              </div>
              
          </div>
                      </div>
                      <div class="card-body">
                          <div class="table-responsive">
                              <table class="table table-bordered table-md ">
                                  <thead >
                                      <tr>
                                          <th scope="col" class="text-left" >Month</th>
                                          <th scope="col" class="text-left">Invoice No</th>
                                          <th scope="col" class="text-right">Amount</th>
                                          <th scope="col" class="text-right">TDS</th>
                                          <th scope="col" class="text-right" >Total</th>
                                          <th scope="col" class="text-left" >Credit Note</th>
                                          <th scope="col" class="text-left">Paid date (due date)</th>
                                          <th class="text-left">Status </th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-if="vb.length < 0 ">
                                          <td colspan="6" class="text-left">No Data Found</td>
                                      </tr>
                                      <tr  v-else class="" v-for="(vb_in ,i) in vb " :key="i">
                                          <td class="text-left">{{ date_format(vb_in.vb_month) }}</td>
                                          <td class="text-left">{{ vb_in.invoice_id }}</td>
                                          <td class="text-right"><span v-html="currency_check(numberWithCommas(vb_in.withouttax))"></span></td>
                                          <td class="text-right"><span v-html="currency_check(numberWithCommas(vb_in.tds_deduct))"></span></td>
                                          <td class="text-right"><span v-html="currency_check(numberWithCommas(vb_in.withouttax-vb_in.tds))"></span></td>
                                          <td class="text-right"><span v-html="currency_check(numberWithCommas(vb_in.credit_amt))"></span></td>
                                          <td class="text-left">
                                              <span v-if="vb_in.payment_status==0">
                                                  ( {{ date_format(vb_in.due_date) }} )
                                              </span>
                                              <span v-else>
  {{ date_format(vb_in.pay_date) }}
                                              </span>
                                          
                                          </td>
                                          <td class="text-center"> {{ vb_in.payment_status==0 ? 'Pending' :  vb_in.payment_status==1 ?  'Processing' :  vb_in.payment_status==2 ? 'Paid' :'Rejected' }}</td>
                                          
                                          
                                      </tr>
                                      
                                      
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div>
  </template>
  
  <script>
  import axios from 'axios'
  import helpers from '../../../mixins/helpers'; 
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Multiselect from "vue-multiselect";
  export default {
      mixins: [helpers],
  data:()=>({
  vb:[],
  vendor_ids:[],
  message:"",
  message1:"",
  amount:"",
  amount1:"",
  curncy:"",
  vendor_id_value:"",
  dateRange: {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
          },
  }),
  components: {DateRangePicker,Multiselect},
  methods:
  {
      income_expense_list() {
        axios.post("/admin/vb_reports",{
            month:this.dateRange,
            vendor_id:this.vendor_id_value.value
        }).then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              this.vb=resp.data.vb;
             
              
              
            }
          }
        });
      },
      currency_check(amt) {
      return '<i class="mdi '+this.curncy+'"></i>'+amt;
              },
              getProfile() {
                  axios.get("/api/get_profile").then((resp) => {
                      if (resp.data.status_code == 200) {
                          this.curncy= resp.data.users.currencys;
                      }
                  });
              },
              //dateFormat (classes, date) {
         /*  if (!classes.disabled) {
            classes.disabled = date.getTime() < new Date()
          }
          return classes */
        //}
              dateChange()
              {
                  this.income_expense_list();
              },
              get_venor_id() {
        /* axios.post("/api/get_vendor_ids").then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              this.vendor_ids = resp.data.vendor_ids;
            }
          }
        }); */
  
        axios.post("/admin/get_all_vendors_list").then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              for (var i = 0; i < resp.data.vendors.length; i++) {
                this.vendor_ids.push({
                  value: resp.data.vendors[i].user_id,
                  name:
                    resp.data.vendors[i].first_name +
                    " " +
                    resp.data.vendors[i].last_name,
                });
              }
            }
          }
        });
  
      },
      apply_filter()
      {
        this.income_expense_list();
      }
  },
  mounted()
  {
      this.getProfile();
      this.income_expense_list();
      this.get_venor_id();
  }
  }
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  <style>
  
  </style>